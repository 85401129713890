import React from 'react';
import AdminRoleAssignment from './AdminRoleAssignment'; // This will be created in the next step

const AdminDashboard = () => {
  return (
    <div>
      <h1>Admin Dashboard</h1>
      <div>
        <h2>Manage Roles</h2>
        <AdminRoleAssignment />  {/* This component allows role assignment */}
      </div>
      {/* Additional admin features like managing announcements can be added here later */}
    </div>
  );
};

export default AdminDashboard;
