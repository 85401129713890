import axios from 'axios';

// const API_URL = "https://devaa.ptoadmin.org/api/announcements";
const API_URL = "http://localhost:8082/api/announcements";

export const getAllAnnouncements = () => {
    return axios.get(API_URL);
};

export const createAnnouncement = (announcement) => {
    return axios.post(API_URL, announcement);
};
