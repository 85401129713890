import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFundraisingBySlug } from '../services/FundraisingService';

const FundraisingPage = () => {
  const { slug } = useParams();
  const [fundraising, setFundraising] = useState(null);

  useEffect(() => {
    const fetchFundraising = async () => {
      try {
        const { data } = await getFundraisingBySlug(slug);
        setFundraising(data);
      } catch (err) {
        console.error("Failed to fetch fundraising event:", err);
      }
    };

    fetchFundraising();
  }, [slug]);

  if (!fundraising) {
    return <p>Loading fundraising details...</p>;
  }

  return (
    <div className="container">
      <h1>{fundraising.fundraisingTitle}</h1>
      <p>{fundraising.fundraisingDescription}</p>
      <p>Date: {new Date(fundraising.fundraisingDate).toLocaleDateString()}</p>
    </div>
  );
};

export default FundraisingPage;
