import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { getAllEvents } from '../services/EventService';
import { getAllFundraising } from '../services/FundraisingService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';  // Import FontAwesomeIcon
import { faUser } from '@fortawesome/free-solid-svg-icons';  // Import the user icon
import './Navbar.css';  // Custom styles for Navbar
import LoginModal from './LoginModal';  // Import the LoginModal component

const NavigationBar = () => {
  const [events, setEvents] = useState([]);
  const [fundraisingEvents, setFundraisingEvents] = useState([]);
  const [showFundraising, setShowFundraising] = useState(false);  // State to control dropdown visibility
  const [showLoginModal, setShowLoginModal] = useState(false);  // State for controlling the login modal visibility
  const navigate = useNavigate();  // Use React Router to handle navigation

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const { data } = await getAllEvents();
        setEvents(data);
      } catch (err) {
        console.error("Error fetching events:", err);
      }
    };

    const fetchFundraising = async () => {
      try {
        const { data } = await getAllFundraising();
        setFundraisingEvents(data);
      } catch (err) {
        console.error("Error fetching fundraising events:", err);
      }
    };

    fetchEvents();
    fetchFundraising();
  }, []);

  // Function to handle showing the login modal
  const handleShowLogin = () => setShowLoginModal(true);
  const handleCloseLogin = () => setShowLoginModal(false);

  return (
    <>
      <Navbar collapseOnSelect expand="lg" className="custom-navbar" variant="dark">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              src="/images/pto-logo.png"
              width="120"
              height="120"
              className="d-inline-block align-top"
              alt="RLC PTO"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link as={Link} to="/">Home</Nav.Link>
              <Nav.Link as={Link} to="/membership">Membership</Nav.Link>

              {/* Events Dropdown */}
              <NavDropdown title="Events" id="collasible-nav-dropdown">
                {events.length > 0 ? (
                  events.map((event) => (
                    <NavDropdown.Item as={Link} to={`/events/${event.slug}`} key={event.event_id}>
                      {event.eventName}
                    </NavDropdown.Item>
                  ))
                ) : (
                  <NavDropdown.Item disabled>No Events Available</NavDropdown.Item>
                )}
              </NavDropdown>

              {/* Fundraising Dropdown */}
              <NavDropdown
                title="Fundraising"
                id="collasible-nav-dropdown"
                show={showFundraising}
                onMouseEnter={() => setShowFundraising(true)}
                onMouseLeave={() => setShowFundraising(false)}
                onClick={() => navigate('/fundraising')}
              >
                {fundraisingEvents.length > 0 ? (
                  fundraisingEvents.map((fundraising) => (
                    <NavDropdown.Item as={Link} to={`/fundraising/${fundraising.slug}`} key={fundraising.fundraising_id}>
                      {fundraising.fundraisingTitle}
                    </NavDropdown.Item>
                  ))
                ) : (
                  <NavDropdown.Item disabled>No Fundraising Available</NavDropdown.Item>
                )}
              </NavDropdown>

              <Nav.Link as={Link} to="/sponsorship">Sponsorship</Nav.Link>
              <Nav.Link as={Link} to="/volunteer">Volunteer</Nav.Link>
              <Nav.Link as={Link} to="/about">About</Nav.Link>
              <Nav.Link as={Link} to="/gallery">Gallery</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link as={Link} to="/membership" className="btn btn-light">Become a PTO Member</Nav.Link>

              {/* Login Icon Button */}
              <Nav.Link onClick={handleShowLogin} style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faUser} /> {/* Use FontAwesome user icon */}
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Login Modal */}
      <LoginModal show={showLoginModal} handleClose={handleCloseLogin} />
    </>
  );
};

export default NavigationBar;
