import axios from 'axios';

const API_URL = "https://devaa.ptoadmin.org/api/events";

export const getAllEvents = () => {
    return axios.get(API_URL);
}; 

export const createEvent = (event) => {
    return axios.post(API_URL, event);
};

export const getEventBySlug = (slug) => {
    return axios.get(`${API_URL}/${slug}`);
};